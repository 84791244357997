import React from 'react';
import { Link } from "react-router-dom";
import './index.css';

function Footer() {
    return (
        <div className="footer">
            <a href="/">
                <img className="logo" src="/footer/logo_footer.png" alt="" />
            </a>
            <h1 className="company">北京光陆信息技术有限公司</h1>
            <p className="desc">客服电话：010-86461895</p>
            <p className="copyright">Copyright © 2018 北京光陆信息技术有限公司 ALL Rights Reserved&nbsp;
            <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备18009671号</a>
                <br />
                <br />
                <a href="/bjglwww.png" target="_blank">编号京网文〔2019)1352-144号</a>
            </p>
            <p className="copyright">
                <a href="/report">纠纷处理</a>&nbsp;&nbsp;
                <a href="/monitor/main">家长监护</a>
            </p>
            <p className="tip">
                抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防上当受骗。
                <br />
                适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
            </p>
        </div>
    );
}

export default Footer;
