import React from 'react';
import MonitorNav from '../../components/monitorNav'

function Process() {
  return (
    <div className="process">
      <MonitorNav />
      <h2>家长监护服务进度查询</h2>
      <p>
      如果您已经申请家长监护服务，您可以通过给服务邮箱发邮件或是拨打客服电话的方式进行进度查询，了解您所提交的服务申请最新处理进展。服务期间，如果您对需要提交的信息或者处理结果有疑问，或者其他任何问题，您均可以随时联系我们，我们将由专门负责的客服主管为您提供咨询解答服务，或者配合、指导您解决问题。
      <br />
        <br />
        申请家长监护服务，请下载：
        <br/>
        <br/>
        <a href="/monitor/shenqing.zip" className="download">监护服务申请文档</a>
        <br/>
        <br/>
        您的账号已被纳入家长监护系统，如果您并对此操作有疑义，请下载：
        <br/>
        <br/>
        <a href="/monitor/jiechu.zip" className="download">监护服务申请文档</a>
        <br/>
        <br/>
      </p>
    </div>
  );
}

export default Process;
