
import React, { useEffect } from 'react';
import './index.css';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

const style: {
    width: number,
    height: number,
} = {
    width: 1242/4.6,
    height: 2208/4.6
}

function MySwiper() {

    useEffect(() => {
        new Swiper('#certify .swiper-container', {

            watchSlidesProgress: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            loopedSlides: 5,
            autoplay: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // 暂时不显示分页
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            // },
            on: {
                progress: function () {
                    const that: any = this
                    for (var i = 0; i < that.slides.length; i++) {
                        var slide = that.slides.eq(i);
                        var slideProgress = that.slides[i].progress;
                        var modify = 1;
                        var translate = slideProgress * modify * style.width / 7 + 'px';
                        var scale = 1 - Math.abs(slideProgress) / 10;
                        var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                        if (Math.abs(slideProgress) > 2) {
                            slide.css('opacity', 0);
                        }
                    }
                },
                /** 
                 * swiper的ts声明有bug
                 * 这里要修改node_modules/@types/swiper/index.d.ts里面，注释为
                 * Register event handlers
                 * 里的声明，原值
                 * on?: { [key in SwiperEvent]?: () => void };
                 * 改为
                 * on?: { [key in SwiperEvent]?: (any?) => void };
                 **/ 
                setTransition: function (transition: any) {
                    const that: any = this
                    for (var i = 0; i < that.slides.length; i++) {
                        var slide = that.slides.eq(i)
                        slide.transition(transition);
                    }
                }
            }
        });

    });

    return (
        <div id="certify">
            <section className="swiper-container">
                <div className="swiper-wrapper">
                    {
                        '1'.repeat(5).split('').map((item, index) =>
                            (<div key={index} className="swiper-slide" style={style}>
                                <img src={"swiper/cyzkg_" + (index + 1) + ".jpg"} alt="" style={style} />
                            </div>)
                        )
                    }
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </section>
        </div>
    );
}

export default MySwiper;
