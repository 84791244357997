import React from 'react';
import MonitorNav from '../../components/monitorNav'

function Explain() {
  return (
    <div className="explain">
      <MonitorNav />
      <h2>家长监护服务说明</h2>
      <p>
        家长监护工程充分考虑家长的实际需求，当家长发现自己的孩子玩游戏过于沉迷的时候，由家长提供合法的监护人资质证明、游戏名称账号、以及家长对于限制强度的愿望等信息，可对处于孩子游戏沉迷状态的账号采取几种限制措施，解决未成年人沉迷网游的不良现象，如限制孩子每天玩游戏的时间区间和长度，也可以限制只有周末才可以游戏，或者完全禁止。
      <br />
        <br />
        申请家长监护服务，请下载：
        <br/>
        <br/>
        <a href="/monitor/shenqing.zip" className="download">监护服务申请文档</a>
        <br/>
        <br/>
        您的账号已被纳入家长监护系统，如果您并对此操作有疑义，请下载：
        <br/>
        <br/>
        <a href="/monitor/jiechu.zip" className="download">监护服务申请文档</a>
        <br/>
        <br/>
      </p>
      <img className="process_vertical" src="/monitor/process_vertical.png" alt="process_vertical"/>

    </div>
  );
}

export default Explain;
