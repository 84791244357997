import React from 'react';
import { Link, withRouter } from "react-router-dom";
import './index.css';

function Header(props: any) {
  console.log(props.location.pathname);
  return (
    <header >
      <div className="header">
        <a href="/"><img src="/header/logo_header.png" alt="" /></a>
        <ul>
          {/* <Link to="/">
            <li className={props.location.pathname === '/' ? 'active' : ''}>
              开局一栋楼
            </li>
          </Link> */}
          <Link to="/">
            <li className={props.location.pathname === '/' ? 'active' : ''}>
              梦幻消消消
            </li>
          </Link>
          <Link to="/cyzkg">
            <li className={props.location.pathname === '/cyzkg' ? 'active' : ''}>
              成语主考官
            </li>
          </Link>
          <Link to="/gdhzmj">
            <li className={props.location.pathname === '/gdhzmj' ? 'active' : ''}>
              广德红中麻将
            </li>
          </Link>
          {/* <a href="https://www.zhipin.com/gongsi/5bc1a128c4d98fc61HJy2ty8FA~~.html?ka=search_rcmd_company_5bc1a128c4d98fc61HJy2ty8FA~~_custompage">
            <li>
              加入我们
            </li>
          </a> */}
        </ul>
      </div>
    </header >
  );
}

export default withRouter(Header);
