import React from 'react';
import MonitorNav from '../../components/monitorNav'

function Progress() {
  return (
    <div className="progress">
      <MonitorNav />
      <h2>网络游戏未成年人家长监护工程</h2>
      <p>
        “网络游戏未成年人家长监护工程”是由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷游戏的行为成为可能。
        <br/>
        <br/>
        具体实施细则如下：
        <br/>
        <br/>
        一、申请条件
        <br/>
        1、申请人需为被监护未成年人的法定监护人；
        <br/>
        2、申请人的被监护人年龄小于18周岁；
        <br/>
        3、申请人需为大陆公民，不含港、澳、台人士。
        <br/>
        <br/>
        二、申请需要提交材料：
        <br/>
        1、监护人信息表（包含监护人的身份证明复印件）；<a href="/monitor/shenqing/doc1.doc" className="download">参见附件表一</a>（请点击右键，选择“目标另存为”）
        <br/>
        2、被监护人信息表（包含被监护人所玩游戏相关信息及身份证明复印件）；<a href="/monitor/shenqing/doc2.doc" className="download">参见附件表二</a>（请点击右键，选择“目标另存为”）
        <br/>
        3、填写未成年人家长监护申请书、起诉保证书、授权书并手工签字（需下载，填写并打印，签字）；<a href="/monitor/shenqing/doc3.doc" className="download">参见附件表三</a>（请点击右键，选择“目标另存为”）
        <br/>
        4、申请人与被监护人的监护关系证明文件（户口簿或有关机关出具的证明文件）。
        <br/>
        <br/>
        三、申请方式
        <br/>
        您可以通过联系电话、邮件的方式联系我们：
        <br/>
        服务热线：010-62978156
        <br/>
        传真服务：010-62978156
        <br/>
        服务邮箱：gamemarketing@hortorgames.com
        <br/>
        <br/>
        四、申请流程：
        <br/>
        申请人首先致电游戏公司获得服务号码，使用传真、信件、来访等方式提交资料；受理公司在收到提交材料后，将在48小时内进行电话回访（不含法定节假日），由受理公司对内容进行核查。资料提交合格且核对正确的情况下，由受理公司对该请求予以受理；资料提交不全面的，由受理公司进行回访，并提示申请人继续补充材料；资料提交不合格且不正确的，受理公司有权对该请求予以拒绝，并附说明理由。
        <br/>
        <br/>
        五、其他要求：
        <br/>
        1、申请人应提交较完备的申请材料，对未提供的信息要及时补充；可请熟知电脑、互联网、游戏的人员进行协助，以便提供详实资料；
        <br/>
        2、申请人‰应保证提交的信息真实有效；对于提供虚假信息或伪造证件，监护关系证明书追究法律责任的权利。
        <br/>
        <br/>
        <br/>
        <a href="/monitor/shenqing/doc1.doc" className="download">下载：附件表一监护人信息表</a>（请点击右键，选择“目标另存为”）
        <br/>
        <a href="/monitor/shenqing/doc2.doc" className="download">下载：附件表二被监护人信息表</a>（请点击右键，选择“目标另存为”）
        <br/>
        <a href="/monitor/shenqing/doc3.doc" className="download">下载：附件表三网络游戏未成年人家长监护申请书</a>（请点击右键，选择“目标另存为”）
      </p>
    </div>
  );
}

export default Progress;
