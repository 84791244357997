import React from 'react';

import './index.css';
import Banner from '../../components/banner/ttxxx'
import Description from '../../components/description/ttxxx'

import Introduction from '../../components/introduction/ttxxx'


function Home() {
  return (
    <div className="ttxxx">
            <Banner />
            <div className="container">
              <Description />
              <Introduction />
            </div>
         
    </div>
  );
}

export default Home;
