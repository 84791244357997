import React from 'react';
import './index.css';

function Description() {
  return (
    <div className="description">
      <div className="title_outer">
        <img className="title" src="description/title_ttxxx.png" alt="" />
        <img className="title_decoration" src="description/title_decoration.png" alt=""/>
      </div>
      <div className="desc_outer">
        <p className="desc">
        你有多久没有玩一款消除类休闲小游戏了？立即扫码加入梦幻消消消！只要动动手指消除星星方块就能领红包，通关越多，红包越多。还在等什么赶快来下载吧！
        <br/>
        <br/>
        上架申请中，尽情期待......
        </p>
        
        {/* <img className="download_code" src="description/qrcode_ttxxx.png" alt=""/>
        <a href="https://h5-1256136475.file.myqcloud.com/ttxxx/files/ttxxx-TT_0-v2.1-20200605.apk"><img className="download_button" src="description/download_button.png" alt=""/></a> */}
      </div>
    </div>
  );
}

export default Description;
