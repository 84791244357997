import React from 'react';
import './index.css';

interface props {
    titleName: string,
    desc: string
}

function Title({ titleName = '', desc  = ''}: props) {
    return (
        <div className="title">
            <div className="title_outer">
                <img className="block" src="feature/block.png" alt="" />
                <p className="title_name">{titleName}</p>
                <img className="block" src="feature/block.png" alt="" />
            </div>
            <p className="desc">{desc}</p>
        </div>
    );
}

export default Title;
