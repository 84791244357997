import React from 'react';
import './index.css';

function Banner() {
    return (
        <div className="banner">
            <img src="banner/banner_gdhzmj.jpg" alt="" />
        </div>
    );
}

export default Banner;
