import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import Header from './components/header'
import Footer from './components/footer'
import Home from './pages/home'
import Cyzkg from './pages/cyzkg'
import Ttxxx from './pages/ttxxx'
import Gdhzmj from './pages/gdhzmj'
import Report from './pages/report'
import Monitor from './pages/monitor'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/report">
            <Report />
          </Route>
          <Route path="/monitor">
            <Monitor />
          </Route>
          <Route path="/cyzkg">
            <Cyzkg />
          </Route>
          <Route path="/gdhzmj">
            <Gdhzmj />
          </Route>
          <Route path="/">
            <Ttxxx />
          </Route>
          
          {/* <Route path="/">
            <Home />
          </Route> */}

        </Switch>
        <Footer />
      </Router>
    </div>

  );
}

export default App;
