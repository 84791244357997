import React from 'react';

import './index.css';
import Banner from '../../components/banner/cyzkg'
import Description from '../../components/description/cyzkg'

import Introduction from '../../components/introduction/cyzkg'


function Home() {
  return (
    <div className="cyzkg">
            <Banner />
            <div className="container">
              <Description />
              <Introduction />
            </div>
         
    </div>
  );
}

export default Home;
