import React from 'react';

import './index.css';
import Banner from '../../components/banner/gdhzmj'
import Description from '../../components/description/gdhzmj'

import Introduction from '../../components/introduction/gdhzmj'


function Home() {
  return (
    <div className="gdhzmj">
            <Banner />
            <div className="container">
              <Description />
              <Introduction />
            </div>
         
    </div>
  );
}

export default Home;
