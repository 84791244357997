import React from 'react';
import './index.css';

function Description() {
  return (
    <div className="description">
      <div className="title_outer">
        <img className="title" src="description/title_gdhzmj.png" alt="" />
        <img className="title_decoration" src="description/title_decoration.png" alt=""/>
      </div>
      <div className="desc_outer">
        <p className="desc">
        《广德红中麻将》是一款经典棋牌游戏，简单快速上手，提供真人实时对战。游戏中还增加了“赖子牌”红中，让对局更加紧张刺激，将胡牌进行到底！只要你有手机，就可以跨越时间空间，无论你在吃饭还是休息，都可以随时随地来上一局，从此不再一缺三！
        <br/>
        <br/>
        快扫描二维码，开启你的雀神之旅吧~
        </p>
        
        <img className="download_code" src="description/qrcode_gdhzmj.jpg" alt=""/>
        {/* <a href="https://h5-1256136475.file.myqcloud.com/gdhzmj/files/gdhzmj-TT_0-v2.1-20200605.apk"><img className="download_button" src="description/download_button.png" alt=""/></a> */}
      </div>
    </div>
  );
}

export default Description;
