import React from 'react';
import './index.css';

function Description() {
  return (
    <div className="description">
      <div className="title_outer">
        <img className="title" src="description/title_cyzkg.png" alt="" />
        <img className="title_decoration" src="description/title_decoration.png" alt=""/>
      </div>
      <div className="desc_outer">
        <p className="desc">
        轻松答题，秀才养成，升官买屋收人才，你想要的这里都有！
        <br/>
        <br/>
        上架申请中，尽情期待......
        </p>
        
        {/* <img className="download_code" src="description/qrcode_cyzkg.png" alt=""/>
        <a href="https://h5-1256136475.file.myqcloud.com/cyzkg/files/cyzkg-TT_0-v2.1-20200605.apk"><img className="download_button" src="description/download_button.png" alt=""/></a> */}
      </div>
    </div>
  );
}

export default Description;
