import React from 'react';
import './index.css';


function Progress() {

    return (
        <div className="progress">
            <img className="progress_bar_bg" src="introduction/progress_bar_bg.png" alt="" />
            <img className="progress_bar" src="introduction/progress_bar.png" alt="" />
        </div>
    );
}

export default Progress;
