import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './index.css';
import Main from './main'
import Process from './process'
import Explain from './explain'
import Progress from './progress'
import Faq from './faq'

function Monitor() {
  return (
    <div className="monitor">
      <Switch>
        <Route path="/monitor/main">
          <Main />
        </Route>
        <Route path="/monitor/process">
          <Process />
        </Route>
        <Route path="/monitor/explain">
          <Explain />
        </Route>
        <Route path="/monitor/progress">
          <Progress />
        </Route>
        <Route path="/monitor/faq">
          <Faq />
        </Route>
      </Switch>
    </div>
  );
}

export default Monitor;
