import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import './index.css';

function MonitorNav() {
    return (
        <div className="monitorNav">
            <Link to="/monitor/progress"><h1 className={useRouteMatch("/monitor/progress") ? 'checked' : ''} >家长监护服务申请流程</h1></Link>
            <Link to="/monitor/explain"><h1 className={useRouteMatch("/monitor/explain") ? 'checked' : ''} >家长监护服务说明</h1></Link>
            <Link to="/monitor/process"><h1 className={useRouteMatch("/monitor/process") ? 'checked' : ''} >家长监护服务进度查询</h1></Link>
            <Link to="/monitor/faq"><h1 className={useRouteMatch("/monitor/faq") ? 'checked' : ''} >家长监护服务FAQ</h1></Link>

            <div className="download_wall">
                <h4>
                    <img src="/monitor/icon_download.png" alt="" />&nbsp;
                    传真文档下载
                </h4>

                <a href="/monitor/shenqing.zip" className="download">监护服务申请文档</a>
                <a href="/monitor/jiechu.zip" className="download">账号疑义申诉文档</a>
            </div>
        </div>
    );
}

export default MonitorNav;
