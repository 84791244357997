import React from 'react';
import './index.css';
import Title from '../common/title'
import Swiper from '../common/swiper/ttxxx'
import Progress from '../common/progress'

function Introduction() {


    return (
        <div className="introduction">
            <div className="top">
                <Title titleName="游戏略览" desc="A Brief Introduction to Games" />
                <div className="icons">
                    <img src="introduction/progress_but_1.png" alt="" />
                    <img src="introduction/progress_but_2.png" alt="" />
                    <img src="introduction/progress_but_3.png" alt="" />
                    <img src="introduction/progress_but_4.png" alt="" />
                    <img src="introduction/progress_but_5.png" alt="" />
                </div>

                <Progress />

            </div>
            <Swiper />
            <div className="bottom"></div>
        </div>

    );
}

export default Introduction;
