import React from 'react';
import MonitorNav from '../../components/monitorNav'

function Faq() {
  return (
    <div className="faq">
      <MonitorNav />
      <h2>家长监护服务进度查询</h2>
      <p>
        Q：我可以通过哪些渠道与我们公司取得联系？
        <br/>
        A：您可以通过联系电话、邮件的方式联系我们：
        <br/>
        服务热线：010-62978156
        <br/>
        服务传真：010-62978156
        <br/>
        服务邮箱：gamemarketing@hortorgames.com
        <br/>
        <br/>
        Q：你们这项服务收费吗？
        <br/>
        A：这项服务是免费的，我们会在对您提交的信息确认无误后，尽快处理。
        <br/>
        <br/>
        Q：你们的服务过程大概是什么样的？
        <br/>
        A：第一步：联系我们， 并提交信息；
        <br/>
        第二步：会有专业的家长监护主管回访您；
        <br/>
        第三步：如信息确认过程中，有需要您配合补充资料的情况，我们将联系您；
        <br/>
        第四步：资料确认无误后，会按您的需求对监护账号进行处理。
        <br/>
        <br/>
        Q：针对未成年人家长监护系统的受理时间是什么？
        <br/>
        A：我们的服务受理时间是：周一至周日，早9：00至晚22：00
        <br/>
        <br/>
        Q：我发送的信息通过什么方式提供给你们？
        <br/>
        A：通过监护系统页面提交相关信息，并可通过传真、信件或快递的方式发送给我们即可。
        <br/>
        服务传真：010-62978156
        <br/>
        服务邮箱：gamemarketing@hortorgames.com
        <br/>
        <br/>
        Q：我要对我的被监护人申请监管，需要提供哪些信息？
        <br/>
        A：我们需要您提供以下信息：
        <br/>
        1、派出所出示的监护关系证明；
        <br/>
        2、监护人信息表；
        <br/>
        3、被监护人信息表；
        <br/>
        4、网络游戏未成年人家长监护服务申请表；
        <br/>
        请注意：申请表中涉及签名的部分需要手写签名。
        <br/>
        <br/>
        Q：为什么还得提交起诉保证书和授权书？
        <br/>
        A：起诉保证书的作用是当我们按监护人的需求，在对所提交信息审核无误后，进行控制的过程中，如有用户向我公司提出书面异议，我公司可按照监护人提供的联系地址向其发送书面通知，且监护人保证在我公司通知规定的时间内，按照我公司要求对异议人提起诉讼，以确认被申请账号的归属和使用权利。如监护人在上述期限内不对异议人提起诉讼，我公司将视为此监护人同意终止处理其申请，并由我公司自主决定是否解除对被申请帐号的控制措施，监护人没有任何异议。
        <br/>
        授权书的作用是当我公司对被监护人账号采取控制措施或其他调查措施后，一旦有第三人对我公司的行为提出异议，在得到提起需求的申请人同意并授权后，我公司可以将提起需求申请人的真实身份资料和联系方式提供给该第三人，以方便该第三人对提供虚假信息或恶意干扰第三人游戏权利者提起诉讼。由此而引起的一切法律责任均由此申请人承担。
        <br/>
        <br/>
        Q：为什么我提供的信息错误，会对第三方的利益造成损害呢？
        <br/>
        A：因为有些信息，如账号、角色名相似度比较高，且角色名命名时还可能有特殊符号。如果您提交的信息错误，会导致我们对账号锁定失败或锁定错误。一旦我们对账号采取了措施，会影响其他玩家的游戏利益，同时也会延误您的申请处理进度。
        <br/>
        <br/>
        Q：你们不相信我是他(她)的监护人吗?
        <br/>
        A：为避免在处理过程中可能出现的问题，我们需要您提供与账号使用者必要的监护证明关系材料。
        <br/>
        <br/>
        Q：你们表中有些信息我提供不了，我该怎么办？
        <br/>
        A：您可以提供一些辅助信息，如：IP地址、您被监护人登录游戏的时间点及时长、游戏角色级别、服务器、账号、角色名、游戏名称、职业、游戏截图、视频等。建议您可以问一下周围熟悉的人或者朋友是否有玩游戏，请他们协助您获取一些信息，或者帮助了解一些信息，这样能够加快处理进程。
        <br/>
        <br/>
        Q：你们为什么不相信这是我家人的账号呢？
        <br/>
        A：如果申请人提交资料中的申请人或被监护人的身份证号码与被监控账户注册信息中的身份证号码一致，我们将按与您协商的结果对被监控账号立刻进行处理；如申请人提交的资料中的申请人或被监护人的身份证号码与被监控账户注册信息中的身份证号码无任何关联，为保障申请人的需求能够得到处理，以及账号使用人的利益，我们需要申请人补充提供相关的信息，以确定真实的账号。
        <br/>
        <br/>
        Q：你们让我提供这些信息都是干什么用的？
        <br/>
        A：为了避免给您和其他人带来一些不必要的困扰和麻烦。我们会根据这些信息对账号的归属进行验证和判定，以确保为您服务的准确性。
        <br/>
        <br/>
        Q：我提交信息后你们何时开始受理？
        <br/>
        A：我们在收到您的信息，审核后，会在收到信息的48小时之内与您取得联系，对该服务进行说明，并明确告知是否需要您的后续配合。待最终确定账号无误后，我们将对账号进行监控。15天内如果没有其他客户提出异议，我们会在第16天与您协商处理方案并进行处理。
        <br/>
        <br/>
        Q：为什么我想封我孩子的号那么麻烦?
        <br/>
        A：如果您孩子账号注册的身份证与您提供的信息一致，我们将按与您协商的结果对被监控账号立刻进行处理；若您提供的信息与账号信息不一致，为防止可能由于您提供信息错误，而影响账号实际使用者的利益我们需要进行调查，来判断账号使用者与您的关系。通过上述措施，以帮助我们找到正确的账号，保证您能对孩子真实的游戏账号进行有效的监控。
        <br/>
      </p>
    </div>
  );
}

export default Faq;
